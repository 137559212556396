import React, { useEffect } from "react";
import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => {
  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <div
      className="relative app__bg app__wrapper  flex__center section__padding"
      id="about"
    >
      <div className="w-full z-2 flex__center flex-col lg:flex-row">
        <div className=" flex-one flex justify-end items-end flex-col text-right">
          <h1 className="headtext__cormorant">About Us</h1>
          <img src={images.spoon} alt="about_spoon" className="spoon__img" />
          <p className="p__opensans mx-0 my-6 text-primary-gray">
            Step into S.Lounge, where every evening transforms into an
            extraordinary experience. Our carefully curated ambiance combines
            contemporary luxury with timeless elegance, creating the perfect
            setting for memorable moments.
          </p>
          <button type="button" className="custom__button">
            Know More
          </button>
        </div>

        <div className="my-1 mx-2 lg:my-8 lg:mx-16 lg:rotate-0 rotate-90 flex__center">
          <img
            src={images.knife}
            alt="about_knife"
            className="h-80vwh lg:h-screen 2xl:h-70vh"
          />
        </div>

        <div className=" flex-one flex justify-start items-start flex-col text-left">
          <h1 className="headtext__cormorant">Our Legacy</h1>
          <img src={images.spoon} alt="about_spoon" className="spoon__img" />
          <p className="p__opensans mx-0 my-6 text-primary-gray">
            With a heritage of excellence spanning years, S.Lounge has redefined
            luxury entertainment. Our commitment to exceptional service and
            attention to detail has made us the destination for those who seek
            the extraordinary.
          </p>
          <button type="button" className="custom__button">
            Know More
          </button>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
