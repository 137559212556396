import React, { useEffect, useState } from "react";
import { SubHeading } from "../../components";
import { images } from "../../constants";

const Header = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(true);

    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div className="relative min-h-screen bg-[#01392c] overflow-hidden">
      {/* Animated background elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#DCCA87] to-transparent opacity-30" />
        <div className="absolute bottom-0 left-0 w-full h-1 bg-gradient-to-r from-transparent via-[#DCCA87] to-transparent opacity-30" />

        {/* Animated circles */}
        <div className="absolute -top-20 -right-20 w-[400px] h-[400px] rounded-full bg-[#DCCA87]/5 animate-pulse" />
        <div className="absolute -bottom-40 -left-40 w-[600px] h-[600px] rounded-full bg-[#DCCA87]/5 animate-pulse delay-1000" />
      </div>

      <div className="max-w-7xl mx-auto px-6 py-20 lg:py-32 relative z-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
          {/* Text content */}
          <div
            className={`space-y-8 transform ${
              isVisible
                ? "translate-x-0 opacity-100"
                : "-translate-x-full opacity-0"
            } transition-all duration-1000 ease-out`}
          >
            {/* Animated line */}
            <div className="w-20 h-0.5 bg-[#DCCA87] transform origin-left scale-x-0 animate-[scaleRight_1s_ease-out_forwards_0.5s]" />

            <SubHeading
              title="Chase the new flavour"
              className={`transform ${
                isVisible
                  ? "translate-y-0 opacity-100"
                  : "translate-y-10 opacity-0"
              } transition-all duration-700 delay-300`}
            />

            <h1
              className={`text-6xl lg:text-7xl font-bold text-[#DCCA87] leading-tight transform ${
                isVisible
                  ? "translate-y-0 opacity-100"
                  : "translate-y-10 opacity-0"
              } transition-all duration-700 delay-500`}
            >
              The Key To
              <br />
              Fine Dining
            </h1>

            <p
              className={`text-lg text-gray-300 max-w-xl transform ${
                isVisible
                  ? "translate-y-0 opacity-100"
                  : "translate-y-10 opacity-0"
              } transition-all duration-700 delay-700`}
            >
              Welcome to S Lounge Restaurant, where dining is an art form and
              flavors come alive. Innovative tastes with classic culinary
              craftsmanship, creating a unique experience that invites you to
              explore new flavors with every bite.
            </p>

            <button className="px-8 py-4 bg-[#DCCA87]">
              <span className="text-[#01392c]">Explore Menu</span>
            </button>
          </div>

          {/* Image section */}
          <div
            className={`relative transform ${
              isVisible
                ? "translate-x-0 opacity-100"
                : "translate-x-full opacity-0"
            } transition-all duration-1000 ease-out delay-300`}
          >
            {/* Decorative frame */}
            <div className="absolute -inset-4 border border-[#DCCA87]/30 transform -rotate-6 transition-transform duration-300 group-hover:rotate-0" />
            <div className="absolute -inset-4 border border-[#DCCA87]/30 transform rotate-3 transition-transform duration-300 group-hover:rotate-0" />

            {/* Image container */}
            <div className="relative group">
              <img
                src={images.welcome}
                alt="Exquisite dining presentation"
                className="w-full h-auto relative z-10 transform transition-transform duration-500 group-hover:scale-105"
              />

              {/* Image overlay */}
              <div className="absolute inset-0 bg-gradient-to-t from-[#01392c]/50 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
