import React, { useState, useEffect } from "react";
import { Menu, X } from "lucide-react";
import logo from "../../assets/navlogo.png";
import images from "../../constants/images";
import "./Navbar.css";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 20) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const menuItems = [
    { title: "Home", href: "/" },
    { title: "Menu", href: "#menu" },
    { title: "About", href: "/about" },
    { title: "Contact", href: "/contact" },
  ];

  return (
    <nav
      className={`fixed w-full z-50  transition-all duration-300 ${
        scrolled ? "bg-black/80 backdrop-blur-md" : "bg-transparent"
      }`}
    >
      <div className="w-full px-4 sm:px-8 py-2">
        <div className="flex items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
            <Link to="/">
              <img
                src={logo}
                alt="app logo"
                className="h-20 w-20 sm:w-40 2xl:w-52 object-contain"
              />
            </Link>
          </div>

          {/* Desktop Menu */}
          <ul className="lg:flex hidden items-center justify-center flex-1 gap-2">
            {menuItems.map((item) => (
              <li key={item.title}>
                <Link
                  to={item.href}
                  className="text-white my-0 mx-1 duration-150 cursor-pointer hover:text-gray-400"
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>

          {/* Login/Book Table Section */}
          <div className="sm:flex hidden items-center space-x-4">
            <Link
              to="/location"
              className="text-white hover:text-gray-400 transition-colors"
            >
              Location
            </Link>
            <div className="h-4 w-px bg-white/30" />
            <Link
              to="/"
              className="text-white hover:text-gray-400 transition-colors"
            >
              Book Table
            </Link>
          </div>

          {/* Mobile menu button */}
          <div className="lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-white hover:text-gray-300 p-2"
            >
              {isOpen ? (
                <X className="h-6 w-6" />
              ) : (
                <Menu className="h-6 w-6" />
              )}
            </button>
          </div>
        </div>

        {/* Mobile Menu */}
        {isOpen && (
          <div className="fixed top-0 left-0 w-full h-screen bg-black duration-500 flex flex-col z-10">
            <button
              onClick={() => setIsOpen(false)}
              className="absolute top-5 right-5 text-white hover:text-gray-300 p-2"
            >
              <X className="h-6 w-6" />
            </button>

            <ul className="list-none mt-4 p-6">
              {menuItems.map((item) => (
                <li
                  key={item.title}
                  className="m-4 text-center"
                  onClick={() => setIsOpen(false)}
                >
                  <Link
                    to={item.href}
                    className="font-serif text-4xl text-yellow-600 hover:text-white transition-colors"
                  >
                    {item.title}
                  </Link>
                  <img
                    src={images.spoon}
                    alt="spoon underline"
                    className="h-[10px] w-24 mx-auto mt-2"
                  />
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
